<template>
	<div class="flex flex-col">
		<div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
			<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
				<div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
					<table class="min-w-full divide-y divide-gray-200">
						<thead class="bg-gray-50">
							<tr>
								<th
									scope="col"
									class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
									Workspace name
								</th>
								<th
									scope="col"
									class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
									Your role
								</th>
								<th scope="col" class="relative px-6 py-3">
									<span class="sr-only">Current</span>
								</th>
								<th scope="col" class="relative px-6 py-3">
									<span class="sr-only">View</span>
								</th>
							</tr>
						</thead>
						<tbody class="bg-white divide-y divide-gray-200">
							<tr v-for="team in teams" :key="team.team_id">
								<td class="px-6 py-4 whitespace-nowrap">
									<div class="text-sm text-gray-900">{{ team.name }}</div>
								</td>
								<td class="px-6 py-4 whitespace-nowrap">
									<div class="text-sm text-gray-900">{{ team.permission }}</div>
								</td>
								<td class="py-4 whitespace-nowrap">
									<div
										v-if="activeTeamId == team.team_id"
										class="bg-yellow-700 w-20 text-center font-bold rounded text-white">
										Current
									</div>
								</td>
								<td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
									<button @click="viewTeam(team.team_id)" class="btn btn-default-outline btn-small">
										<span v-html="arrow"></span>
									</button>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { crudable } from "@/mixins/Crudable";
import { mapGetters } from "vuex";
import Button from "@/components/ui/Button";

let arrow = `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>`;

export default {
	mixins: [crudable],

	created() {
		this.getTeams();
	},

	components: {
		// 'v-button' : Button
	},

	data() {
		return {
			arrow,
			teams: [],
		};
	},

	props: {},

	methods: {
		// submitTeamForm(event) {
		//   const data = new FormData(event.target);
		//   console.dir(Object.fromEntries(data.entries()));
		// },

		viewTeam(teamId) {
			this.$router.push({
				name: "workspace-dashboard",
				params: { teamId: teamId },
			});
		},

		async getTeams() {
			try {
				let response = await this.$api.resource("users").fetchOne(this.auth.user.email);
				// console.dir(response);
				this.teams = response.data.teams;
			} catch (e) {
				console.log(`Blablah: ${e}`);
			}
		},

		addTeam(team) {
			this.teams.push(team);
		},
	},

	computed: {
		...mapGetters(["auth", "appLoading", "activeTeamId"]),
	},
};
</script>
<style></style>
