<template>
	<div class="flex flex-col space-y-4">
		<div v-if="invites.length == 0" class="w-full text-center mt-5 font-bold text-lg">No invites</div>
		<div v-for="invite in invites" :key="invite.user_id" class="w-1/2">
			<TeamInvite
				:userId="userId"
				:teamId="invite.team_id"
				:permission="invite.permission"
				:teamName="invite.team_name"
				@crudActionStatus="handleInviteResponseCrud" />
		</div>
	</div>
</template>

<script>
import { crudable } from "@/mixins/Crudable";
import Button from "@/components/ui/Button";
import TeamInvite from "@/components/user/TeamInvite";
export default {
	mixins: [crudable],

	created() {
		if (this.userId) {
			this.setResourceEndpoint("userTeamInvites", { user_id: this.userId });
			this.fetchAll();
		}
	},

	components: {
		TeamInvite,
		// 'v-button': Button
	},

	data() {
		return {
			invites: [],
		};
	},

	props: ["userId"],

	methods: {
		handleInviteResponseCrud(resourceId, e, payload) {
			for (var i = 0; i < this.invites.length; i++) {
				if (this.invites[i].team_id == resourceId) {
					this.invites.splice(i, 1);
					break;
				}
			}
		},
	},

	watch: {
		resourceStatus(status) {
			if (status.status == "loaded") {
				this.invites = this.resource.team_invites;
			}
		},
	},

	computed: {},
};
</script>
<style></style>
