<template>
	<!-- <div class="flex justify-between">
        <div class="heading heading-big">{{ resource.name }}</div>
    </div> -->
	<div>
		<Tabs>
			<Tab name="Your workspaces" :selected="true">
				<div class="lg:w-10/12">
					<div v-if="fsipCreateTeamRole" class="flex justify-between flex-row-reverse mb-3">
						<v-button @click="createTeam" class="btn btn-add-new"
							>New workspace <span class="ml-3" v-html="plus_icon"></span
						></v-button>
					</div>
					<TeamList ref="teamList" />
				</div>
			</Tab>
			<Tab name="Workspace invites">
				<div class="lg:w-10/12">
					<TeamInviteList :userId="userId" />
				</div>
			</Tab>
		</Tabs>
	</div>

	<Modal v-show="modal" :widthPercent="60" @close="modal = false">
		<template v-slot:header>
			<h3>Create new workspace</h3>
		</template>

		<template v-slot:body>
			<CreateTeamForm @crudActionStatus="handleCrudAction" />
		</template>
	</Modal>
</template>

<script>
import { crudable } from "@/mixins/Crudable";
import { mapGetters } from "vuex";
import CreateTeamForm from "@/components/user/CreateTeamForm.vue";
import TeamList from "@/components/user/TeamList";
import TeamInviteList from "@/components/user/TeamInviteList";
import Button from "@/components/ui/Button";
import Tab from "@/components/ui/Tab";
import Tabs from "@/components/ui/Tabs";
import Modal from "@/components/Modal";

const plus_icon = `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
                    </svg>`;

export default {
	mixins: [crudable],

	created() {
		this.userId = this.auth.user.email;
		this.resourceId = this.userId;
		this.setResourceEndpoint("users");
		this.fetchOne();
		this.fsipCreateTeamRole = this.auth.roles.fsipRoles.includes("fsip-create-teams");
	},

	components: {
		TeamList,
		TeamInviteList,
		Tab,
		Tabs,
		Modal,
		CreateTeamForm,
		"v-button": Button,
	},

	props: [],

	data() {
		return {
			modal: false,
			fsipCreateTeamRole: false,
			plus_icon,
		};
	},

	methods: {
		createTeam() {
			this.modal = true;
		},
		handleCrudAction(resourceId, e, payload) {
			if (e == "created") {
				payload.permission = "ADMIN";
				this.$refs.teamList.addTeam(payload);
				this.modal = false;
			}
		},
	},

	watch: {
		$route(to, from) {
			// console.log(to, from)
			this.modal = false;
		},
	},

	computed: {
		...mapGetters(["auth", "appLoading"]),
	},
};
</script>

<style></style>
