<template>
	<div class="card">
		<div class="flex justify-between">
			<div>
				<div>Workspace name: {{ this.teamName }}</div>
				<div>Permission: {{ this.permission }}</div>
			</div>
			<v-button @click="acceptInvite" class="btn btn-success btn-small" :loading="crudLoading">Accept</v-button>
			<v-button @click="rejectInvite" class="btn btn-warning btn-small" :loading="crudLoading">Reject</v-button>
		</div>
	</div>
</template>

<script>
import { crudable } from "@/mixins/Crudable";
import Button from "@/components/ui/Button";

export default {
	mixins: [crudable],

	created() {
		this.resourceId = this.teamId;
		this.setResourceEndpoint("userTeamInvites", { user_id: this.userId });
	},

	components: {
		"v-button": Button,
	},

	props: ["teamId", "userId", "permission", "teamName"],

	data() {
		return {};
	},

	methods: {
		acceptInvite() {
			// Reset the resource because we only need the resourceId
			this.resource = {
				id: this.teamId,
			};
			this.update();
		},

		rejectInvite() {
			this.delete();
		},
	},

	watch: {
		resourceStatus(status) {
			if (status.status == "deleted" || status.status == "updated") {
				this.resetResource();
				// TODO make this better!
				// Hack for now until we can update the team list properly
				window.location.reload();
			}
		},
	},

	computed: {
		// crudName() {
		//     if (this.resourceId) {
		//         return 'Delete'
		//     }
		//     return null
		// }
	},
};
</script>

<style></style>
