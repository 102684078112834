<template>
	<form @submit.prevent="save" class="form-bare">
		<label class="flex flex-col font-bold w-full">
			<span>Name</span>
			<input
				class="border form-input mt-1 p-2"
				name="name"
				v-model="resource.name"
				placeholder="Workspace Name"
				required />
		</label>

		<v-button type="submit" class="btn btn-success" :loading="crudLoading">Create workspace</v-button>
	</form>
</template>

<script>
import { crudable } from "@/mixins/Crudable";
import { mapGetters } from "vuex";
import Button from "@/components/ui/Button";

export default {
	mixins: [crudable],

	mounted() {
		this.setResourceEndpoint("teams");
	},

	components: {
		"v-button": Button,
	},

	data() {
		return {};
	},

	props: {},

	methods: {},

	watch: {
		resourceStatus(status) {
			if (status.status == "created") {
				this.resetResource();
			}
		},
	},

	computed: {},
};
</script>
<style></style>
